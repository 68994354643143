import { useMutation, useQuery } from 'react-query'
import { filterFetch, normalFetch } from '../../fetch-api/get'
import { putApi } from '../../fetch-api/put'
import { useQueryContext } from '../../../contexts/query'
import { useMainContext } from '../../../contexts/main'
import { makeQueryFilters } from '../../../utils/query-maker'
import { postApi } from '../../fetch-api/post'
import useAsyncError from '../../../hooks/common/useAsyncError'

const USERS_KEYS = {
	GET_USERS: 'get-users',
	CHANGE_USER_STATUS: 'change-user-status',
	GET_A_USER: 'get-a-user',
	UPDATE_USER: 'update-user',
	USER_SESSIONS: 'user-sessions',
	MOBILE_KYC: 'mobile-kyc',
	BIRTH_KYC: 'birth-kyc',
	REMOVE_2FA: 'remove-2fa',
	BANK_KYC: 'bank-kyc',
	VERIFY_BANK: 'verify-bank',
	CREATE_USER: 'create-user',
	USER_AFFILIATES: 'user-affiliates',
	FINNOTECH_MOBILE: 'finnotech-mobile',
	FINNOTECH_BANK: 'finnotech-bank',
	FINNOTECH_BIRTH: 'finnotech-birth',
	USER_KYC_STATUS: 'user-kyc-status',
	CARD_TO_IBAN: 'card-to-iban',
	ZIBAL_BANK: 'zibal-bank',
	ZIBAL_BIRTHDATE: 'zibal-birthdate',
	ZIBAL_MOBILE: 'zibal-mobile',
}

const useUsersQuery = (filters, pagination, extra, csv) => {
	const { lang } = useMainContext()
	const newFilters = { ...extra, ...filters }
	const params = { ...makeQueryFilters(newFilters, lang), ...pagination }
	if (csv) {
		params.export = 'csv'
	}

	return useQuery(USERS_KEYS.GET_USERS, () => filterFetch(params, 'users'), {
		cacheTime: 0,
	})
}

const useChangeUserStatus = () => {
	const { queryClient, setToast } = useQueryContext()

	return useMutation(USERS_KEYS.CHANGE_USER_STATUS, ({ id, data }) => putApi(data, `users/${id}`), {
		onSuccess: () => {
			setToast({
				show: true,
				message: 'operation-success',
			})
			queryClient.invalidateQueries(USERS_KEYS.GET_USERS)
		},
	})
}

const useChangeUserKycStatus = () => {
	const { queryClient, setToast } = useQueryContext()

	return useMutation(
		USERS_KEYS.USER_KYC_STATUS,
		({ id, data }) => putApi(data, `users/kyc/${id}`),
		{
			onSuccess: () => {
				setToast({
					show: true,
					message: 'operation-success',
				})
				queryClient.invalidateQueries(USERS_KEYS.GET_USERS)
			},
		}
	)
}

const useGetAUser = (id) => {
	return useQuery([USERS_KEYS.GET_A_USER, id], () => normalFetch(`users/${id}`), {
		enabled: false,
		select: (res) => res?.data?.data,
	})
}

const useCreateUser = () => {
	const { setToast } = useQueryContext()

	return useMutation(USERS_KEYS.CREATE_USER, (data) => postApi(data, 'users'), {
		onSuccess: () => {
			setToast({
				show: true,
				message: 'operation-success',
			})
		},
	})
}

const useUpdateAUser = () => {
	const { setToast } = useQueryContext()

	return useMutation(
		USERS_KEYS.UPDATE_USER,
		(payload) => putApi(payload.data, `users/${payload.id}`),
		{
			onSuccess: () => {
				setToast({
					message: 'operation-success',
					show: true,
				})
			},
		}
	)
}

const useGetUserSessions = (params, id) => {
	return useQuery(USERS_KEYS.USER_SESSIONS, () => filterFetch(params, `users/sessions/${id}`), {
		enabled: false,
		select: (res) => res?.data,
	})
}

const useRequestUserMobileKyc = () => {
	const throwError = useAsyncError()

	return useMutation(USERS_KEYS.MOBILE_KYC, (data) => postApi(data, 'users/kyc/mobile', throwError))
}

const useRequestBirthKyc = () => {
	const throwError = useAsyncError()
	return useMutation(USERS_KEYS.BIRTH_KYC, (data) =>
		postApi(data, 'users/kyc/birthDate', throwError)
	)
}

const useRequestBankKyc = () => {
	const throwError = useAsyncError()
	return useMutation(USERS_KEYS.BANK_KYC, (data) => postApi(data, 'users/kyc/bank', throwError))
}

const useRequestFinnotechMobileKyc = () => {
	const throwError = useAsyncError()

	return useMutation(USERS_KEYS.FINNOTECH_MOBILE, (data) =>
		postApi(data, 'users/kyc/mobile/finnotech', throwError)
	)
}

const useRequestFinnotechBirthKyc = () => {
	const throwError = useAsyncError()
	return useMutation(USERS_KEYS.FINNOTECH_BIRTH, (data) =>
		postApi(data, 'users/kyc/birthDate/finnotech', throwError)
	)
}

const useRequestFinnotechBankKyc = () => {
	const throwError = useAsyncError()
	return useMutation(USERS_KEYS.FINNOTECH_BANK, (data) =>
		postApi(data, 'users/kyc/bank/finnotech', throwError)
	)
}

const useRemoveUser2fa = () => {
	return useMutation(USERS_KEYS.REMOVE_2FA, (id) => putApi({}, `users/${id}/2fa/remove`))
}

const useVerifyBankAccount = () => {
	const { setToast } = useQueryContext()

	return useMutation(
		USERS_KEYS.VERIFY_BANK,
		(payload) => putApi(payload.data, `users/${payload.userId}/account/${payload.bankId}`),
		{
			onSuccess: () => {
				setToast({
					show: true,
					message: 'operation-success',
				})
			},
		}
	)
}

const useGetUserAffiliates = (id) => {
	return useQuery([USERS_KEYS.USER_AFFILIATES, id], () => normalFetch(`affiliates/admin/${id}`), {
		select: (res) => res?.data?.data,
	})
}

const useChangeCardToIban = () => {
	return useMutation(USERS_KEYS.CARD_TO_IBAN, (payload) =>
		postApi(payload, `users/kyc/cardNoToIban/zibal`)
	)
}

const useRequestZibalBankKyc = () => {
	return useMutation(USERS_KEYS.ZIBAL_BANK, (payload) => postApi(payload, `users/kyc/iban/zibal`))
}

const useRequestZibalBirthdateKyc = () => {
	return useMutation(USERS_KEYS.ZIBAL_BIRTHDATE, (payload) =>
		postApi(payload, `users/kyc/birthDate/zibal`)
	)
}

const useRequestZibalMobileKyc = () => {
	return useMutation(USERS_KEYS.ZIBAL_MOBILE, (payload) =>
		postApi(payload, `users/kyc/mobile/zibal`)
	)
}

export {
	useUsersQuery,
	useChangeUserStatus,
	useGetAUser,
	useGetUserSessions,
	useRequestUserMobileKyc,
	useRequestBirthKyc,
	useRemoveUser2fa,
	useRequestBankKyc,
	useVerifyBankAccount,
	useUpdateAUser,
	useCreateUser,
	useRequestFinnotechMobileKyc,
	useRequestFinnotechBirthKyc,
	useRequestFinnotechBankKyc,
	useGetUserAffiliates,
	useChangeUserKycStatus,
	useChangeCardToIban,
	useRequestZibalBankKyc,
	useRequestZibalBirthdateKyc,
	useRequestZibalMobileKyc,
}
